import Vue from 'vue';
import axios    from 'axios';
import VueAxios from 'vue-axios';
import qs from 'qs';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  config.headers['Source'] = 'instructor';

  return config;
});
axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if(error && error.response && error.response.status){
        switch (error.response.status) {
          case 404:
            if(window.location.href !== (window.location.origin + '/') ){
              window.location.href = "/404";
            }
            else {
              return Promise.reject(error);
            }
            break;
          default:
            return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    });
Vue.use(VueAxios, axios);

export default axios;