import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './request'
import auth from './config';
import 'bootstrap';
import moment from 'vue-moment'
import VCalendar from 'v-calendar';
import 'regenerator-runtime/runtime'
import * as dateHelper from './helpers/dateHelper';

Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(moment);

Vue.config.productionTip = false

Vue.filter('toHMTime', function (value) {
    var minutes = Math.floor((value) / 60);
    var seconds = value - (minutes * 60);

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ':' + seconds;
});

Vue.prototype.$formatDate = dateHelper.formatDate;
Vue.prototype.$formatDateTime = dateHelper.formatDateTime;
Vue.prototype.$formatTime = dateHelper.formatTime;
Vue.prototype.$is24Hours = dateHelper.use24HourFormat;

const env = process.env.VUE_APP_ENV || "PROD"; 

const colors = {
  LOCAL: "#c4dcff",
  PROD: "#C4112E",
  QA: "#c4dcff",
};

const colorsMainBody = {
  LOCAL: "#c4dcff",
  PROD: "#FFFF",
  QA: "#c4dcff",
};

document.documentElement.style.setProperty("--bg-color", colors[env]);
document.documentElement.style.setProperty("--bg-main-color", colorsMainBody[env]);

new Vue({
    router,
    store,
    auth: auth,
    http: axios,
    render: h => h(App)
}).$mount('#app')
